exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-be-a-volunteer-js": () => import("./../../../src/pages/be-a-volunteer.js" /* webpackChunkName: "component---src-pages-be-a-volunteer-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-online-help-desk-js": () => import("./../../../src/pages/online-help-desk.js" /* webpackChunkName: "component---src-pages-online-help-desk-js" */),
  "component---src-pages-our-projects-js": () => import("./../../../src/pages/our-projects.js" /* webpackChunkName: "component---src-pages-our-projects-js" */),
  "component---src-pages-who-we-are-js": () => import("./../../../src/pages/who-we-are.js" /* webpackChunkName: "component---src-pages-who-we-are-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-latest-news-js": () => import("./../../../src/templates/latest-news.js" /* webpackChunkName: "component---src-templates-latest-news-js" */),
  "component---src-templates-latest-news-project-js": () => import("./../../../src/templates/latest-news-project.js" /* webpackChunkName: "component---src-templates-latest-news-project-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */)
}

